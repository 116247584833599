/* Font Family */
/* Borda arredondada */
body > #root .providerBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 32%;
  cursor: default;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .providerBox {
    flex: 0 0 auto !important;
  }
}
body > #root .providerBox.disabled {
  background-color: #e3e3e3;
}
body > #root .providerBox.disabled > .providerData > .infos > h3 {
  color: #23568a;
}
body > #root .providerBox.disabled > .providerData > .infos > small {
  color: #666;
}
body > #root .providerBox:not(:nth-child(3n+3)) {
  margin-right: 2%;
}
body > #root .providerBox:nth-child(n+4) {
  margin-top: 2%;
}
body > #root .providerBox:hover {
  border-color: #C0C0C0;
}
body > #root .providerBox:hover > .providerData > img {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root .providerBox > .providerData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  body > #root .providerBox > .providerData {
    flex: 0 0 auto !important;
  }
}
body > #root .providerBox > .providerData > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root .providerBox > .providerData > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root .providerBox > .providerData > .infos > h3 {
  margin: 0 0 5px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  color: #183d62;
}
body > #root .providerBox > .providerData > .infos > small {
  margin-top: 5px;
  font-style: italic;
  color: #999;
}
body > #root .providerBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 -1px -1px;
}
@media screen and (max-width: 767px) {
  body > #root .providerBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .providerBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 25px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .providerBox > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .providerBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root .providerBox > .buttons > button.load {
  cursor: default;
  background-color: #be591e;
}
body > #root .providerBox > .buttons > button.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root .providerBox > .buttons > button.download,
body > #root .providerBox > .buttons > button.tariffs,
body > #root .providerBox > .buttons > button.pisCofins {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
  padding: 25px 20px;
}
@media screen and (max-width: 767px) {
  body > #root .providerBox > .buttons > button.download,
  body > #root .providerBox > .buttons > button.tariffs,
  body > #root .providerBox > .buttons > button.pisCofins {
    flex: 0 0 auto !important;
  }
}
body > #root .providerBox > .buttons > button:hover:not(:disabled) {
  color: #FFF;
}
body > #root .providerBox > .buttons > button:hover:not(:disabled).download {
  background-color: #7c3a14;
}
body > #root .providerBox > .buttons > button:hover:not(:disabled).tariffs {
  background-color: #408300;
}
body > #root .providerBox > .buttons > button:hover:not(:disabled).pisCofins {
  background-color: #a90054;
}
body > #root .providerBox > .buttons > button:hover:not(:disabled).editUser {
  background-color: #e69500;
}
body > #root .providerBox > .buttons > button:hover:not(:disabled).deleteUser {
  background-color: #e60000;
}
body > #root .providerBox > .buttons > button > span {
  margin-left: 10px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.date,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .header > span.date,
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.date {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.group,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.group {
  width: 70px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.modality,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.modality {
  width: 100px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.type,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.type {
  width: 110px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.dem_tusd,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.dem_tusd,
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.con_tusd,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.con_tusd,
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.con_te,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.con_te {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 95px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .header > span.dem_tusd,
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.dem_tusd,
  body > .ReactModalPortal > div > .modal > .modal-body > .header > span.con_tusd,
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.con_tusd,
  body > .ReactModalPortal > div > .modal > .modal-body > .header > span.con_te,
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.con_te {
    flex: 0 0 auto !important;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
