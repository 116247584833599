/* Font Family */
/* Borda arredondada */
body > #root .groupBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 18.4%;
  max-width: 20%;
  cursor: default;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .groupBox {
    flex: 0 0 auto !important;
  }
}
body > #root .groupBox:not(:nth-child(5n+5)) {
  margin-right: 2%;
}
body > #root .groupBox:nth-child(n+6) {
  margin-top: 2%;
}
body > #root .groupBox:hover {
  border-color: #C0C0C0;
}
body > #root .groupBox:hover > .groupData > img {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root .groupBox > .groupData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  height: 100px;
  padding: 35px 20px 20px;
}
@media screen and (max-width: 767px) {
  body > #root .groupBox > .groupData {
    flex: 0 0 auto !important;
  }
}
body > #root .groupBox > .groupData > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root .groupBox > .groupData > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root .groupBox > .groupData > .infos > .status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background-color: #999;
}
body > #root .groupBox > .groupData > .infos > .status.active {
  background-color: #7ee57e;
}
body > #root .groupBox > .groupData > .infos > .status.inactive {
  background-color: #ef6e63;
}
body > #root .groupBox > .groupData > .infos > h3 {
  margin: 0 0 5px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  color: #183d62;
}
body > #root .groupBox > .groupData > .infos > small {
  position: relative;
  margin-top: -2px;
  color: #D0D0D0;
  font-size: 40px;
}
body > #root .groupBox > .alertData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  height: 100px;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  body > #root .groupBox > .alertData {
    flex: 0 0 auto !important;
  }
}
body > #root .groupBox > .alertData > p {
  margin: 0 0 10px;
  font-size: 12px;
}
body > #root .groupBox > .alertData > small {
  font-size: 10.5px;
  font-style: italic;
  color: #EA4335;
}
body > #root .groupBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 -1px -1px;
}
@media screen and (max-width: 767px) {
  body > #root .groupBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .groupBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .groupBox > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .groupBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root .groupBox > .buttons > button.confirm,
body > #root .groupBox > .buttons > button.cancel {
  background-color: #C0C0C0;
}
body > #root .groupBox > .buttons > button:hover:not(:disabled) {
  color: #FFF;
}
body > #root .groupBox > .buttons > button:hover:not(:disabled).unitsGroup {
  background-color: #1a53be;
}
body > #root .groupBox > .buttons > button:hover:not(:disabled).editGroup {
  background-color: #e69500;
}
body > #root .groupBox > .buttons > button:hover:not(:disabled).deleteGroup {
  background-color: #e60000;
}
body > #root .groupBox > .buttons > button:hover:not(:disabled).confirm {
  background-color: #22A822;
}
body > #root .groupBox > .buttons > button:hover:not(:disabled).cancel {
  background-color: #EA4335;
}
body > #root .groupBox > .buttons > button > span {
  margin-left: 10px;
}
