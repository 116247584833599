/* Font Family */
/* Borda arredondada */
body > #root > .container.users > .content > main {
  padding: 0;
}
body > #root > .container.users > .content > main > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: fixed;
  top: 79px;
  left: 81px;
  right: 20px;
  margin: 0 !important;
  padding: 12px 20px;
  background-color: #f2f2f2;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  body > #root > .container.users > .content > main > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.users > .content > main > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding: 8px;
  width: 35px;
  height: 35px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container.users > .content > main > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.users > .content > main > .buttons > button.addUser {
  background-color: #009a00;
}
body > #root > .container.users > .content > main > .buttons > button:hover {
  color: #FFF;
}
body > #root > .container.users > .content > main > .buttons > button:hover.addUser {
  background-color: #006700;
}
body > #root > .container.users > .content > main > .users {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-top: 60px;
  padding: 8px 20px 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .container.users > .content > main > .users {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.users > .content > main > .users.loading {
  min-height: calc(100% - 60px);
}
body > #root > .container.users > .content > main > .users.loading > .loading-single {
  padding-bottom: 93px;
}
