/* Font Family */
/* Borda arredondada */
body > #root .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: auto;
  margin-left: 5px;
  padding: 8px;
  height: 35px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .buttons > button:hover {
  color: #FFF;
}
body > #root > .container.cl > .content > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .headerLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: fixed;
  top: 79px;
  left: 81px;
  right: 20px;
  margin: 0 !important;
  padding: 12px 20px;
  height: 60px;
  background-color: #f2f2f2;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main > .headerLine {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .headerLine > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main > .headerLine > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button {
  margin: 0 10px 0 0;
  background-color: #C0C0C0;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button.load {
  margin: 0 10px 0 0;
  cursor: default;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button.load.clients {
  background-color: #007dcf;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button.load.download {
  background-color: #be591e;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).clients {
  background-color: #004f83;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).download {
  background-color: #7c3a14;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).proinfa {
  background-color: #cc0033;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).fees {
  background-color: #6b9900;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).feesPayment {
  background-color: #486600;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).ercap {
  background-color: #009973;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).bkpEnergy {
  background-color: #aa00cc;
}
body > #root > .container.cl > .content > main > .headerLine > .buttons > button:hover:not(:disabled).salesOff {
  background-color: #006acc;
}
body > #root > .container.cl > .content > main > .headerLine > .searchBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  width: 31.75%;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main > .headerLine > .searchBox {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .headerLine > .searchBox > #search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 30px 10px 15px;
  width: 100%;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main > .headerLine > .searchBox > #search {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .headerLine > .searchBox > #search:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
  outline-width: 0;
}
body > #root > .container.cl > .content > main > .headerLine > .searchBox > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  color: #EA4335;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main > .headerLine > .searchBox > button {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .clients {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  align-content: flex-start;
  margin-top: 60px;
  padding: 8px 20px 20px;
}
@media screen and (max-width: 767px) {
  body > #root > .container.cl > .content > main > .clients {
    flex: 0 0 auto !important;
  }
}
body > #root > .container.cl > .content > main > .clients.loading {
  min-height: calc(100% - 60px);
}
body > #root > .container.cl > .content > main > .clients.loading > .loading-single {
  padding-bottom: 93px;
}
