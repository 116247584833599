/* Font Family */
/* Borda arredondada */
body > .ReactModalPortal .modal-body > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > #inputFile {
  display: none;
}
body > .ReactModalPortal .modal-body > form > .fileBox > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 20px;
  padding: 10px 20px;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox > select {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > button {
  cursor: pointer;
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.selectFile {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-right: 20px;
  padding: 20px 30px;
  color: #333;
  background-color: #f5f5f5;
  border: 2px dashed #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox > button.selectFile {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #22A822;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile:disabled {
  cursor: default;
  color: rgba(255, 255, 255, 0.5);
  background-color: #7ee57e;
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile:not(:disabled):hover {
  color: #FFF;
  background-color: #156815;
}
body > .ReactModalPortal .modal-body > form > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  max-height: 41vh;
  overflow: auto;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader > * {
  font-weight: bold;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader:not(:first-child) {
  margin-top: 10px;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader > * {
  font-size: 0.75em;
  text-align: left;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader > .code {
  width: 25%;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader > .unit {
  width: 65%;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader > .datas {
  width: 90px;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoHeader > .btn {
  width: 30px;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn:not(:first-child) {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #F0F0F0;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > * {
  text-align: left;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .code {
  width: 25%;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .unit {
  width: 65%;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .datas {
  width: 90px;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .btn {
  width: 30px;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .btn > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 3px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  background-color: #2bd22b;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .btn > button {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .btn > button.minus {
  background-color: #FBBC04;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .btn > button:hover {
  color: #FFF;
  background-color: #197e19;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .infos > .btn > button:hover.minus {
  background-color: #977102;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin: 10px 10px 0 0;
  padding: 20px;
  display: none !important;
  background-color: #e3e3e3;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data.openned {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data.openned {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data > .box {
  margin-right: 2%;
  padding: 10px;
  width: 18.4%;
  background-color: #FFF;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data > .box:nth-child(n+6) {
  margin-top: 10px;
}
body > .ReactModalPortal .modal-body > form > .content > .infoBox.proinfa > .infoColumn > .data > .box:nth-child(5n+5) {
  margin-right: 0;
}
