/* Font Family */
/* Borda arredondada */
body > .ReactModalPortal .modal-body > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .form-group:not(:first-child),
body > .ReactModalPortal .modal-body > form > .input-group:not(:first-child) {
  margin-top: 20px;
}
body > .ReactModalPortal .modal-body > form > .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .form-group {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group,
body > .ReactModalPortal .modal-body > form > .input-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .form-group > .input-group,
  body > .ReactModalPortal .modal-body > form > .input-group {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > label,
body > .ReactModalPortal .modal-body > form > .input-group > label {
  font-size: 0.8em;
  font-weight: 600;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input,
body > .ReactModalPortal .modal-body > form > .input-group > input,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select,
body > .ReactModalPortal .modal-body > form > .input-group > select {
  width: 100%;
  padding: 10px;
  background-color: #FFF;
  border: 1px solid #999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input:disabled,
body > .ReactModalPortal .modal-body > form > .input-group > input:disabled,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select:disabled,
body > .ReactModalPortal .modal-body > form > .input-group > select:disabled {
  background-color: #F0F0F0;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input.error,
body > .ReactModalPortal .modal-body > form > .input-group > input.error,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select.error,
body > .ReactModalPortal .modal-body > form > .input-group > select.error {
  background-color: #fdefee;
  border: 1px solid #d62516;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input.error:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .input-group > input.error:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select.error:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .input-group > select.error:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input.error:not(:disabled):focus,
body > .ReactModalPortal .modal-body > form > .input-group > input.error:not(:disabled):focus,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select.error:not(:disabled):focus,
body > .ReactModalPortal .modal-body > form > .input-group > select.error:not(:disabled):focus {
  background-color: #fef8f7;
  border-color: #a81d11;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .input-group > input:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .input-group > select:not(:disabled):hover,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > input:not(:disabled):focus,
body > .ReactModalPortal .modal-body > form > .input-group > input:not(:disabled):focus,
body > .ReactModalPortal .modal-body > form > .form-group > .input-group > select:not(:disabled):focus,
body > .ReactModalPortal .modal-body > form > .input-group > select:not(:disabled):focus {
  border-color: #333;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w50,
body > .ReactModalPortal .modal-body > form > .input-group.w50 {
  width: 50px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w100,
body > .ReactModalPortal .modal-body > form > .input-group.w100 {
  width: 100px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w140,
body > .ReactModalPortal .modal-body > form > .input-group.w140 {
  width: 140px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w150,
body > .ReactModalPortal .modal-body > form > .input-group.w150 {
  width: 150px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w200,
body > .ReactModalPortal .modal-body > form > .input-group.w200 {
  width: 200px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w220,
body > .ReactModalPortal .modal-body > form > .input-group.w220 {
  width: 220px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w250,
body > .ReactModalPortal .modal-body > form > .input-group.w250 {
  width: 250px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w300,
body > .ReactModalPortal .modal-body > form > .input-group.w300 {
  width: 300px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w350,
body > .ReactModalPortal .modal-body > form > .input-group.w350 {
  width: 350px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w400,
body > .ReactModalPortal .modal-body > form > .input-group.w400 {
  width: 400px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w450,
body > .ReactModalPortal .modal-body > form > .input-group.w450 {
  width: 450px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w500,
body > .ReactModalPortal .modal-body > form > .input-group.w500 {
  width: 500px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.w550,
body > .ReactModalPortal .modal-body > form > .input-group.w550 {
  width: 550px;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group.mg-none,
body > .ReactModalPortal .modal-body > form > .input-group.mg-none {
  margin: 0;
}
body > .ReactModalPortal .modal-body > form > .form-group > .input-group:not(:first-child) {
  margin-left: 15px;
}
