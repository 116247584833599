/* Font Family */
/* Borda arredondada */
body > #root > .container > .content > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  width: 100%;
  height: 79px;
  border-bottom: 1px solid #E0E0E0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > .content > header {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > .content > header > h1 {
  margin: 0;
  font-size: 2em;
  font-weight: 900;
  color: #222222;
}
body > #root > .container > .content > header > h1 > small {
  font-size: 0.75em;
  font-weight: 300;
  color: #999;
}
body > #root > .container > .content > header > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > .content > header > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > .content > header > .infos > * {
  margin-left: 20px;
}
body > #root > .container > .content > header > .infos > .user {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 40px;
  height: 40px;
  color: #B0B0B0;
  background-color: #e0e0e0;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
@media screen and (max-width: 767px) {
  body > #root > .container > .content > header > .infos > .user {
    flex: 0 0 auto !important;
  }
}
