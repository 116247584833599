/* Font Family */
/* Borda arredondada */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
body > .ReactModalPortal > .ReactModal__Overlay {
  position: relative;
  display: block;
  margin: 0;
  padding: 20px;
  text-align: center;
  overflow-y: auto;
  background-color: rgba(192, 192, 192, 0.7) !important;
  z-index: 99999;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content {
  margin: 30px auto 50px;
  width: 100%;
  max-width: 750px;
  background-color: #FFF;
  border: none;
  -moz-box-shadow: 2px 2px 15px -15px #000;
  -webkit-box-shadow: 2px 2px 15px -15px #000;
  box-shadow: 2px 2px 15px -15px #000;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  color: #FFF;
  background-color: #0e2339;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-header {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-header > * {
  margin: 0;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-header > h3 {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.2em;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-header > button {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-header > button:hover {
  color: #FFF;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-body {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-body > .alert {
  margin-top: 25px;
  font-size: 0.8em;
  font-weight: bold;
  color: #cc0000;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  background-color: #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: 1px solid #8c8c8c;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button#btnDelete {
  background-color: #cc0000;
  border-color: #b30000;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button#btnSave {
  background-color: #006700;
  border-color: #004d00;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button#btnCancel {
  background-color: #666;
  border-color: #595959;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button:hover {
  color: #FFF;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button:hover#btnDelete {
  background-color: #990000;
  border-color: #800000;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button:hover#btnSave {
  background-color: #003400;
  border-color: #001a00;
}
body > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content > .modal-footer > button:hover#btnCancel {
  background-color: #4d4d4d;
  border-color: #404040;
}
