/* Font Family */
/* Borda arredondada */
body > #root .clientBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 32%;
  cursor: default;
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .clientBox {
    flex: 0 0 auto !important;
  }
}
body > #root .clientBox:not(:nth-child(3n+3)) {
  margin-right: 2%;
}
body > #root .clientBox:nth-child(n+4) {
  margin-top: 2%;
}
body > #root .clientBox:hover {
  border-color: #C0C0C0;
}
body > #root .clientBox:hover > .clientData > img {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root .clientBox > .clientData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  body > #root .clientBox > .clientData {
    flex: 0 0 auto !important;
  }
}
body > #root .clientBox > .clientData > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
}
@media screen and (max-width: 767px) {
  body > #root .clientBox > .clientData > .infos {
    flex: 0 0 auto !important;
  }
}
body > #root .clientBox > .clientData > .infos > h3 {
  margin: 0 0 5px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  color: #183d62;
}
body > #root .clientBox > .clientData > .infos > .logo {
  position: absolute;
  top: -32px;
  right: -32px;
  width: 50px;
}
body > #root .clientBox > .clientData > .infos > .logo > img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  /* For IE8 and earlier */
}
body > #root .clientBox > .clientData > .infos > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root .clientBox > .clientData > .infos > .line {
    flex: 0 0 auto !important;
  }
}
body > #root .clientBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 -1px -1px;
}
@media screen and (max-width: 767px) {
  body > #root .clientBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root .clientBox > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0;
  padding: 25px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background-color: #999;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root .clientBox > .buttons > button {
    flex: 0 0 auto !important;
  }
}
body > #root .clientBox > .buttons > button:not(:last-child) {
  border-right: 1px solid #FFF;
}
body > #root .clientBox > .buttons > button.load {
  cursor: default;
  background-color: #be591e;
}
body > #root .clientBox > .buttons > button.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
body > #root .clientBox > .buttons > button:hover:not(:disabled) {
  color: #FFF;
}
body > #root .clientBox > .buttons > button:hover:not(:disabled).download {
  background-color: #7c3a14;
}
body > #root .clientBox > .buttons > button:hover:not(:disabled).proinfa {
  background-color: #cc0033;
}
body > #root .clientBox > .buttons > button:hover:not(:disabled).fees {
  background-color: #6b9900;
}
body > #root .clientBox > .buttons > button:hover:not(:disabled).salesOff {
  background-color: #006acc;
}
body > #root .clientBox > .buttons > button:hover:not(:disabled).datas {
  background-color: #e69500;
}
body > #root .clientBox > .buttons > button > span {
  margin-left: 10px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .alertBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 1px;
  padding: 15px 20px;
  font-size: 11px;
  color: #bf2114;
  background-color: rgba(234, 67, 53, 0.15);
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .alertBox {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.date,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
  width: 120px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .header > span.date,
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.date {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.time,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.time {
  width: 100px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.states,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.states {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .header > span.states,
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.states {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.cons,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.cons {
  width: 120px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.year,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.year,
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.month,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.month,
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.price,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.price {
  width: 33.33%;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.year.err,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.year.err,
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.month.err,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.month.err,
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.price.err,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.price.err {
  color: #bf2114;
}
body > .ReactModalPortal > div > .modal > .modal-body > .header > span.pre,
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span.pre {
  width: 70px;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
