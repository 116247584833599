/* Font Family */
/* Borda arredondada */
body > .ReactModalPortal .modal-body > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .header {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .header > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-weight: bolder;
  width: 80%;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .header > .infos {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .header > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 20px;
  width: 20%;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .header > .buttons {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 15px !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 80%;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line > .infos {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line > .infos > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line > .infos > .item {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line > .infos > .edit {
  display: none;
}
body > .ReactModalPortal .modal-body > .line > .infos > .edit > div > input,
body > .ReactModalPortal .modal-body > .line > .infos > .edit > div > select {
  width: 100%;
  padding: 8px 5px;
}
body > .ReactModalPortal .modal-body > .line > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 20px;
  width: 20%;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line > .buttons {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px 10px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #666;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line > .buttons > .item > button,
  body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:disabled,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: #999 !important;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:hover,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:hover {
  color: #FFF;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:hover:enabled,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:hover:enabled {
  cursor: pointer;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:hover.edit,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:hover.edit {
  background-color: #cc8400;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:hover.del,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:hover.del {
  background-color: #cc0000;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:hover.save,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:hover.save {
  background-color: #336900;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item > button:hover.cancel,
body > .ReactModalPortal .modal-body > .line > .buttons > .edit > button:hover.cancel {
  background-color: #8f0048;
}
body > .ReactModalPortal .modal-body > .line > .buttons > .item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line > .buttons > .item {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line > .buttons > .edit {
  display: none;
}
body > .ReactModalPortal .modal-body > .line.active > .infos > .item {
  display: none !important;
}
body > .ReactModalPortal .modal-body > .line.active > .infos > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line.active > .infos > .edit {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .line.active > .buttons > .item {
  display: none !important;
}
body > .ReactModalPortal .modal-body > .line.active > .buttons > .edit {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > .line.active > .buttons > .edit {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > .header > .infos,
body > .ReactModalPortal .modal-body > .line > .infos > .item,
body > .ReactModalPortal .modal-body > .line > .infos > .edit {
  text-align: left;
  font-size: 13px;
}
body > .ReactModalPortal .modal-body > .header > .infos > .year,
body > .ReactModalPortal .modal-body > .line > .infos > .item > .year,
body > .ReactModalPortal .modal-body > .line > .infos > .edit > .year {
  width: 30%;
}
body > .ReactModalPortal .modal-body > .header > .infos > .month,
body > .ReactModalPortal .modal-body > .line > .infos > .item > .month,
body > .ReactModalPortal .modal-body > .line > .infos > .edit > .month {
  margin-left: 10px;
  width: 35%;
}
body > .ReactModalPortal .modal-body > .header > .infos > .value,
body > .ReactModalPortal .modal-body > .line > .infos > .item > .value,
body > .ReactModalPortal .modal-body > .line > .infos > .edit > .value {
  margin-left: 10px;
  width: 35%;
}
body > .ReactModalPortal .modal-body > button.add {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #b08403;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > button.add {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > button.add:hover {
  cursor: pointer;
  color: #FFF;
  background-color: #977102;
}
