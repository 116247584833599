/* Font Family */
/* Borda arredondada */
body > #root.login {
  height: 100vh !important;
}
body > #root.login > .login-page {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > a.logo {
  margin-bottom: 30px;
}
body > #root.login > .login-page > a.logo > img {
  width: 230px;
}
body > #root.login > .login-page > .boxMessage,
body > #root.login > .login-page > .boxErrors {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  margin: 0 auto 25px;
  padding: 15px 20px;
  width: 100%;
  max-width: 350px;
  color: #FFF;
  text-align: center;
  background-color: #418600;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .boxMessage,
  body > #root.login > .login-page > .boxErrors {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .boxMessage > .message,
body > #root.login > .login-page > .boxErrors > .message {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 5px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .boxMessage > .message,
  body > #root.login > .login-page > .boxErrors > .message {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .boxMessage > button,
body > #root.login > .login-page > .boxErrors > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 0 -5px 0 15px;
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  background: transparent;
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .boxMessage > button,
  body > #root.login > .login-page > .boxErrors > button {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .boxMessage > button:hover,
body > #root.login > .login-page > .boxErrors > button:hover {
  color: #FFF;
}
body > #root.login > .login-page > .boxErrors {
  background-color: #ec3333;
}
body > #root.login > .login-page > .boxErrors > .errors {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .boxErrors > .errors {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .boxErrors > .errors > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 5px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .boxErrors > .errors > span {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .boxErrors > .errors > span:not(:first-child) {
  margin-top: 3px;
}
body > #root.login > .login-page > .body-box {
  padding: 25px;
  width: 100%;
  max-width: 350px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 5px solid #1d4a76;
}
body > #root.login > .login-page > .body-box > form > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 1.3em;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .body-box > form > h3 {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .body-box > form > h3 > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #666;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .body-box > form > h3 > a {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .body-box > form > h3 > a:hover {
  color: #333;
}
body > #root.login > .login-page > .body-box > form > h3 > a > svg {
  margin-right: 10px;
  font-size: 0.8em;
}
body > #root.login > .login-page > .body-box > form > .input-group {
  position: relative;
  margin-bottom: 15px;
}
body > #root.login > .login-page > .body-box > form > .input-group > input {
  padding: 15px 50px 15px 15px;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.7);
  border: transparent;
  z-index: 1;
}
body > #root.login > .login-page > .body-box > form > .input-group > input:focus {
  background: #FFF;
}
body > #root.login > .login-page > .body-box > form > .input-group > input:focus + .icons {
  color: #000;
}
body > #root.login > .login-page > .body-box > form > .input-group > input::-webkit-autofill,
body > #root.login > .login-page > .body-box > form > .input-group > input:-webkit-autofill:hover,
body > #root.login > .login-page > .body-box > form > .input-group > input:-webkit-autofill:focus {
  background: transparent !important;
}
body > #root.login > .login-page > .body-box > form > .input-group > .icons {
  position: absolute;
  top: 14px;
  right: 16px;
  text-align: center;
  color: #666;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root.login > .login-page > .body-box > form > .input-group > button {
  display: block;
  padding: 15px;
  width: 100%;
  color: #FFF;
  cursor: pointer;
  background-color: #23568a;
  border: solid 1px #1d4a76;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root.login > .login-page > .body-box > form > .input-group > button:hover:not(:disabled) {
  background-color: #183d62;
  border: solid 1px #0e2339;
}
body > #root.login > .login-page > .body-box > form > .input-group > button:disabled {
  color: rgba(255, 255, 255, 0.8);
  background-color: #2d70b3;
  border: solid 1px #23568a;
}
body > #root.login > .login-page > .body-box > .forgot {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 15px;
  font-size: 0.8em;
  color: #999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page > .body-box > .forgot {
    flex: 0 0 auto !important;
  }
}
body > #root.login > .login-page > .body-box > .forgot:hover {
  color: #666;
}
body > #root.login > .login-page > .copyright {
  margin-top: 20px;
  font-size: 0.8em;
  color: #666;
}
@media screen and (max-width: 767px) {
  body > #root.login > .login-page {
    padding: 30px;
  }
}
