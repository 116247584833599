/* Font Family */
/* Borda arredondada */
body > #root > .container > .content > footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding: 10px 15px;
  height: 35px;
  font-size: 0.75em;
  color: #666666;
  background-color: #F8F8F8;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  body > #root > .container > .content > footer {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 767px) {
  body > #root > .container > .content > footer {
    font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  }
  body > #root > .container > .content > footer > .version {
    font-weight: 600;
  }
}
