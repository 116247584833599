/* Font Family */
/* Borda arredondada */
body > #root > .container > nav.menu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 100%;
  background-color: #FFF;
  border-right: 1px solid #E0E0E0;
  z-index: 999;
}
@media screen and (max-width: 767px) {
  body > #root > .container > nav.menu {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > nav.menu > .links {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > nav.menu > .links {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > nav.menu > .links > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: auto;
  width: 60px;
  height: 60px;
  color: #A0A0A0;
  z-index: 9;
}
@media screen and (max-width: 767px) {
  body > #root > .container > nav.menu > .links > a {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > nav.menu > .links > a > span {
  position: absolute;
  left: 100%;
  padding: 10px 15px;
  width: 150px;
  font-size: 0.8em;
  display: none;
  color: #FFF;
  background-color: #A0A0A0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-animation: fadeout 0.3s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 0.3s;
  /* Firefox < 16 */
  -ms-animation: fadeout 0.3s;
  /* Internet Explorer */
  -o-animation: fadeout 0.3s;
  /* Opera < 12.1 */
  animation: fadeout 0.3s;
}
body > #root > .container > nav.menu > .links > a > span::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -6px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  background-color: #A0A0A0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}
body > #root > .container > nav.menu > .links > a.active {
  color: #1874B4;
}
body > #root > .container > nav.menu > .links > a.logo {
  width: 60px;
  height: 80px;
  border-bottom: 3px solid #F0F0F0;
}
body > #root > .container > nav.menu > .links > a.logo > img {
  width: 50px;
}
body > #root > .container > nav.menu > .links > a:hover > span {
  display: block;
  -webkit-animation: fadein 0.3s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.3s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.3s;
  /* Internet Explorer */
  -o-animation: fadein 0.3s;
  /* Opera < 12.1 */
  animation: fadein 0.3s;
}
body > #root > .container > nav.menu > a.logout {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 auto;
  width: 60px;
  height: 80px;
  color: #E74444;
  border-top: 3px solid #F0F0F0;
}
@media screen and (max-width: 767px) {
  body > #root > .container > nav.menu > a.logout {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > nav.menu > a.logout > span {
  display: none;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
