/* Font Family */
/* Borda arredondada */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v24/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: #f2f2f2;
  overflow: hidden;
}
body * {
  box-sizing: border-box;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-top: 0;
}
body a {
  text-decoration: none;
  color: #040A10;
}
body hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 35px 0;
  width: 100%;
  height: 3px;
  min-height: 3px;
  background-color: #F0F0F0;
  border: none;
}
@media screen and (max-width: 767px) {
  body hr {
    flex: 0 0 auto !important;
  }
}
body > #root {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  height: 100%;
}
@media screen and (max-width: 767px) {
  body > #root {
    flex: 0 0 auto !important;
  }
}
body > #root > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: calc(100vh - 60px - 35px);
}
@media screen and (max-width: 767px) {
  body > #root > .container {
    flex: 0 0 auto !important;
  }
}
@media screen and (max-width: 480px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px);
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px - 55px);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px - 55px);
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body > #root > .container {
    height: calc(100vh - 60px - 35px - 55px);
  }
}
body > #root > .container > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > .container > .content {
    flex: 0 0 auto !important;
  }
}
body > #root > .container > .content > main {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
body > .ReactModalPortal > div > .modal > .modal-body > .loading-single {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 80px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .loading-single {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .loading-single > img {
  width: 100px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 110.5px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .alert {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .alert-text {
  margin-top: 25px;
  font-size: .8em;
  font-weight: 700;
  color: #d62516;
}
body > .ReactModalPortal > div > .modal > .modal-body > .table-line,
body > .ReactModalPortal > div > .modal > .modal-body .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line,
  body > .ReactModalPortal > div > .modal > .modal-body .header {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .table-line:not(:first-child),
body > .ReactModalPortal > div > .modal > .modal-body .header:not(:first-child) {
  margin-top: 2px;
}
body > .ReactModalPortal > div > .modal > .modal-body > .table-line:not(.header):nth-child(even) > span,
body > .ReactModalPortal > div > .modal > .modal-body .header:not(.header):nth-child(even) > span {
  background-color: #eff8fd;
}
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span,
body > .ReactModalPortal > div > .modal > .modal-body .header > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 10px;
  font-size: 0.9em;
  background-color: #d8eefa;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span,
  body > .ReactModalPortal > div > .modal > .modal-body .header > span {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal > div > .modal > .modal-body > .table-line > span:not(:first-child),
body > .ReactModalPortal > div > .modal > .modal-body .header > span:not(:first-child) {
  margin-left: 2px;
}
body > .ReactModalPortal > div > .modal > .modal-body .header > span {
  font-weight: 600;
  color: #FFF;
  background-color: #12628f;
}
body > .ReactModalPortal > div > .modal > .modal-body .header > span.dem_tusd,
body > .ReactModalPortal > div > .modal > .modal-body .header > span.con_tusd,
body > .ReactModalPortal > div > .modal > .modal-body .header > span.con_te {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal > div > .modal > .modal-body .header > span.dem_tusd,
  body > .ReactModalPortal > div > .modal > .modal-body .header > span.con_tusd,
  body > .ReactModalPortal > div > .modal > .modal-body .header > span.con_te {
    flex: 0 0 auto !important;
  }
}
