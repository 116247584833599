/* Font Family */
/* Borda arredondada */
body > .ReactModalPortal .modal-body > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > #inputFile {
  display: none;
}
body > .ReactModalPortal .modal-body > form > .fileBox > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 20px;
  padding: 10px 20px;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox > select {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > button {
  cursor: pointer;
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.selectFile {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-right: 20px;
  padding: 20px 30px;
  color: #333;
  background-color: #f5f5f5;
  border: 2px dashed #999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox > button.selectFile {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.7);
  background-color: #22A822;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile:disabled {
  cursor: default;
  color: rgba(255, 255, 255, 0.5);
  background-color: #7ee57e;
}
body > .ReactModalPortal .modal-body > form > .fileBox > button.saveFile:not(:disabled):hover {
  color: #FFF;
  background-color: #156815;
}
body > .ReactModalPortal .modal-body > form > .content.liquid {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  max-height: 41vh;
  overflow: auto;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content.liquid {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader > * {
  font-weight: bold;
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader,
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader,
  body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine {
    flex: 0 0 auto !important;
  }
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader:not(:first-child),
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine:not(:first-child) {
  margin-top: 10px;
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader > *,
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine > * {
  margin-right: 10px;
  font-size: 0.75em;
  text-align: left;
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader > .client,
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine > .client {
  width: 55%;
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader > .cnpj,
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine > .cnpj {
  width: 35%;
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader > .date,
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine > .date {
  width: 100px;
}
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoHeader > .value,
body > .ReactModalPortal .modal-body > form > .content.liquid > .infoBox > .infoLine > .value {
  width: 100px;
  text-align: right;
}
